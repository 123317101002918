<script setup>
import {computed} from 'vue'
import {Link} from '@inertiajs/vue3'
import '../../scss/app.scss';

const props = defineProps({status: Number})

const title = computed(() => {
    return {
        503: '503: Сервис не доступен',
        500: '500: Ошибка сервера',
        404: '404: Страница не найдена',
        403: '403: Доступ запрещен',
    }[props.status]
})

const description = computed(() => {
    return {
        503: 'Извините, мы проводим технические работы. Пожалуйста, зайдите немного позже.',
        500: 'Упс, что-то пошло не так на наших серверах.',
        404: 'Извините, страница, которую вы ищете, не была найдена.',
        403: 'Извините, вам запрещен доступ к этой странице.',
    }[props.status]
})
</script>

<template>
    <div class="d-flex flex-column" style="height: 100vh">
        <div class="container m-auto">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <h2>{{ title }}</h2>
                            <div>{{ description }}</div>
                            <Link v-if="status !== 500 && status !== 503" class="mt-2 d-inline-block" href="/">
                                На главную
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
